import { format, isValid, isAfter, isSameDay, isEqual } from 'date-fns';
import { fr } from 'date-fns/locale';
import { gsap } from "gsap";


export const getCurrentTime = (search) => {
    // ex: 2011-07-07T11:20
    if (search) {
        const urlParams = new URLSearchParams(search);
        const date = new Date(urlParams.get('date'));

        if (urlParams.get('date') && isValid(date))
            return (date);
    }
    return new Date();
}

export const getAutoplaySpeed = (search, base) => {
    if (search) {
        const urlParams = new URLSearchParams(search);
        const speed = parseInt(urlParams.get('switch'));

        if (!isNaN(speed))
            return speed;
    }
    return base;
}
export const getJuicerSpeed = (search, base) => {
    if (search) {
        const urlParams = new URLSearchParams(search);
        const speed = parseInt(urlParams.get('social'));

        if (!isNaN(speed))
            return speed;
    }
    return base;
}

export const flattenConfs = (days) => {
    let allConfs = [];
    days.forEach((a) => {
        a.node.confs.forEach(b => {
            b.day = a.node.day;
            b.month = a.node.month;
            b.year= a.node.year;
        });
        allConfs = [...allConfs, ...a.node.confs];
    });
    return allConfs;
}

export const getFormatedDay = (date) => {
    return format(date, "iiii d LLLL y", { locale: fr });
}

export const getEndMoment = (parent, conf) => {
    const { endHour, endMinutes } = conf;
    return new Date(`${parent.year}-${parent.month}-${parent.day}T${endHour}:${endMinutes}`);
}

export const getStartMoment = (parent, conf) => {
    const { startHour, startMinutes } = conf;
    return new Date(`${parent.year}-${parent.month}-${parent.day}T${startHour}:${startMinutes}`);
}

export const getDay = (node) => {
    const { year, month, day } = node;
    return new Date(`${year}-${month}-${day}T00:00`);
}

export const getDayConfs = (conferencesDays, currentDate) => {
    const currentDay = conferencesDays.filter((day) =>
        isSameDay(getDay(day.node), currentDate)
    )[0];
    if (currentDay) {
        return currentDay.node;
    } else {
        return null;
    }
}
export const getMorningConfs = (confs) => {
    return confs.filter((conf) => conf.morning === true);
}
export const getAfternoonConfs = (confs) => {
    return confs.filter((conf) => conf.morning === false);
}

export const getRemainingDayConfs = (conferencesDays, currentDate) => {
    const currentDay = conferencesDays.filter((day) =>
        isSameDay(getDay(day.node), currentDate)
    )[0];
    if (currentDay) {
        return currentDay.node.confs.filter((elem) => {
            const endMoment = getEndMoment(currentDay.node, elem)
            return isAfter(endMoment, currentDate) || isEqual(endMoment, currentDate)
        });
    }
    else
        return null;
}

export const hasRemainingConfs = (day, currentDate) => {
    return day.confs.filter((elem) => {
        const endMoment = getEndMoment(day, elem)
        return isAfter(endMoment, currentDate) || isEqual(endMoment, currentDate)
    }).length > 0;
}

export const getOngoing = (allConfs, currentDate) => {
    const incomingConf = getIncoming(allConfs, currentDate);

    if (!incomingConf) return null;

    const day = {year: incomingConf.year, month: incomingConf.month, day: incomingConf.day};

    if (isConfStarted(day, incomingConf, currentDate)
        && !isConfPassed(day, incomingConf, currentDate)) {
        return incomingConf;
    } else {
        return null;
    }
}

export const isConfPassed = (day, conf, currentDate) => {
    return isAfter(currentDate, getEndMoment(day, conf));
}

export const isConfStarted = (day, conf, currentDate) => {
    return isAfter(currentDate, getStartMoment(day, conf));
}


export const isIncoming = (allConfs, currentDate, day, conf) => {
    return (conf === getIncoming(allConfs, currentDate));
}
export const getIncoming = (allConfs, currentDate) => {
    return (allConfs.find(elem => !isConfPassed({year: elem.year, month: elem.month, day: elem.day}, elem, currentDate)))
}


export const getIsOver = (conferences, currentDate) => {
    let lastDay = conferences[conferences.length - 1].node;
    return isAfter(currentDate, getEndMoment(lastDay, lastDay.confs[lastDay.confs.length - 1]));
}


export const printDatas = (search, conferences, currentDate) => {
    const urlParams = new URLSearchParams(search);
    const debug = urlParams.get('debug');

    if (debug === "true") {
        console.log(`\n\n\nDate Actuelle :\n\n${currentDate}\n\n\n\n`);
        console.log(`\n\nJournées :\n\n\n`);
        conferences.forEach(elem => {
            let day = elem.node;
            console.log(day);
            day.confs.forEach(conf => {
                console.log(`Heures de fin :\n${getEndMoment(day, conf)}\n\n`);
            })
        });
    }
}

export const fadeIn = (dom, callback) => {
    gsap.to(dom, { delay: 0.5, autoAlpha: 1, duration: 0.75, ease: "sine.in", onComplete: callback });
}
export const fadeOut = (dom, callback) => {
    gsap.to(dom, { autoAlpha: 0, duration: 0.75, ease: "sine.in", onComplete: callback });
}